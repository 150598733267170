import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    cities: [],
    city: localStorage.getItem('disk_city'),
    partners: [],
    partner: {},
    related: [],
    lastPartners: [],
    ratings: [],
    user: {},
    loading: false,  
  },
  actions,
  getters,
  mutations,
};