import axios from 'axios';

export default {
	async lastPartnerView(){
		return axios.get('/api/v1/site/last-partner-view');
	},
	async getList(params){
		return axios.get('/api/v1/site/get-partners', {params: params});
	},
	async getDetail(params){
		return axios.get('/api/v1/site/partner-details', {params: params});
	},
	async getPartnerRatings(params){
		return axios.get('/api/v1/site/partner-ratings', {params: params});
	},
	async cities(){
		return axios.get('/api/v1/site/get-cities');
	},
	async clickStats(params){
		return axios.post('/api/v1/site/click-stats-adv', params);
	},
	async addRating(params){
		return axios.post('/api/v1/site/partner-rating', params);
	},
	async addLead(params){
		return axios.post('/api/v1/site/partner-lead', params);
	},
	async recoverPass(params){
		return axios.post('/api/v1/site/partner-recover-pass', params);
	},
	async login(params){
		return axios.post('/api/v1/site/partner-login', params);
	},
	async getUser(){
		return axios.get('/api/v1/site/partner-get-user');
	},
	async register(params){
		return axios.post('/api/v1/site/partner-register', params, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		});
	},
	async updateRegister(params){
		return axios.post('/api/v1/site/partner-register-update', params, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		});
	},
	async dashboard(){
		return axios.get('/api/v1/site/partner-dashboard');
	},
	async myLeads(params){
		return axios.get('/api/v1/site/partner-my-leads', {params: params});
	},
	async leadStatus(params){
		return axios.post('/api/v1/site/partner-lead-status', params);
	},
	async myReviews(params){
		return axios.get('/api/v1/site/partner-my-reviews', {params: params});
	},
	async myProducts(params){
		return axios.get('/api/v1/site/partner-my-products', {params: params});
	},
	async productEdit(params){
		return axios.get('/api/v1/site/partner-product-edit', {params: params});
	},
	async productRegisterUpdate(params){
		return axios.post('/api/v1/site/partner-product-register', params, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		});
	},
}