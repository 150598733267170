import Vue from 'vue';
import Vuex from 'vuex';
//
import Site from './Site';
import Banner from './Banner';
import Blog from './Blog';
import Partner from './Partners';
//

Vue.use(Vuex);

export default new Vuex.Store({
	modules:{
		Site,
		Banner,
		Blog,
		Partner,
	}
});