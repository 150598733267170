import $ from 'jquery'
import '../libs/waypoints/jquery.waypoints.js';

$(function(){
    "use strict";
    $(document).ready(function () {

        /*  [ Main Menu ]
        - - - - - - - - - - - - - - - - - - - - */
        var sub_menu = $('.sub-menu');
        sub_menu.each(function () {
            $(this).parent().find('> a').addClass('has-child').append(' <i class="la la-angle-down la-12"></i>');
        });

        // Check Sub Menu
        $('.right-header .sub-menu').each( function() {
            var width  = $(this).outerWidth();

            if( width > 0 ) {
                var offset = $(this).offset();
                var w_body = $('body').outerWidth();
                var left = offset.left;
                if( w_body < left + width ) {
                    $(this).css('left', '-100%');
                }
            }
        });

        $('.has-child').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('open');
            if($(this).closest(".sub-menu").length <= 0) {
                $('.menu-arrow .has-child').not(this).parent().find(' > .sub-menu').slideUp('fast');
            }
            $(this).parent().find(' > .sub-menu').slideToggle('fast');
            if (!$(this).hasClass( 'open' )) {
                $( this ).parent().find( '.has-child' ).removeClass( 'open' );
            }
        });

        $('.site__menu__icon').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.site-header').find('.popup-background').fadeToggle();
            $(this).parents('.site-header').find('.popup--left').toggleClass('open');
        });

        $('.minicart__open').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.site-header').find('.popup-background').fadeToggle();
            $(this).parents('.site-header').find('.popup--right').toggleClass('open');
        });

        $('.popup__close').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.site-header').find('.popup-background').fadeOut();
            $(this).parent().removeClass('open');
        });

        $('.popup-background').on('click', function (e) {
            e.preventDefault();
            $(this).fadeOut();
            $(this).parents('.site-header').find('.popup').removeClass('open');
        });

        $(document).on('click', '.open-login', function (e) {
            e.preventDefault();
            $('.popup--left').removeClass('open');
            $(this).parents('#wrapper').find('.nav-login').addClass('active');
            $(this).parents('#wrapper').find('.form-log').fadeIn(0);
            $(this).parents('#wrapper').find('.nav-signup').removeClass('active');
            $(this).parents('#wrapper').find('.form-sign').fadeOut(0);
            $(this).parents('#wrapper').find('.popup-background').fadeIn(0);
            $(this).parents('#wrapper').find('.popup-form').toggleClass('open');
        });

        $('.open-signup').on('click', function (e) {
            e.preventDefault();
            $('.popup--left').removeClass('open');
            $(this).parents('#wrapper').find('.nav-signup').addClass('active');
            $(this).parents('#wrapper').find('.form-sign').fadeIn(0);
            $(this).parents('#wrapper').find('.nav-login').removeClass('active');
            $(this).parents('#wrapper').find('.form-log').fadeOut(0);
            $(this).parents('#wrapper').find('.popup-background').fadeIn(0);
            $(this).parents('#wrapper').find('.popup-form').toggleClass('open');
        });

        $('.choose-form a').on('click', function (e) {
            e.preventDefault();
            var id = $(this).attr('href');
            $(this).parents('.popup').find('.form-content').fadeOut(0);
            $(this).parents('.popup').find(id).fadeIn(0);
            $('.choose-form li').removeClass('active');
            $(this).parent().addClass('active');
        });

        $('.search-open').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.site-header').find('.site__search').toggleClass('open');
        });

        $('.search__close').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.site-header').find('.site__search').removeClass('open');
        });

        /*  [ Destinations Popup ]
        - - - - - - - - - - - - - - - - - - - - */

        $('.right-header__languages > a, .right-header__destinations > a, .site__filter a, .site__sort a').on('click', function (e) {
            e.preventDefault();
            $(this).parent().find('ul').toggleClass('open');
        });

        /*  [ Filter ]
        - - - - - - - - - - - - - - - - - - - - */
        $('.site__filter a').on('click', function (e) {
            e.preventDefault();
            $( this ).toggleClass( 'active' );
            $(this).parents( 'body' ).find('.golo-menu-filter').slideToggle();
        });

        $( '.filter-control a' ).on('click', function (e) {
            e.preventDefault();
            $( this ).parents( 'li' ).toggleClass( 'active' );
            $( '.filter-control a' ).not( this ).parents( 'li' ).removeClass( 'active' );
        });

        $(document).mouseup(function (e) {
            var destinations = $('.right-header__destinations'),
                languages = $('.right-header__languages'),
                site__filter = $('.site__filter'),
                site__sort = $('.site__sort');

            if (!destinations.is(e.target) && destinations.has(e.target).length === 0) {
                destinations.find('ul').removeClass('open');
            }

            if (!languages.is(e.target) && languages.has(e.target).length === 0) {
                languages.find('ul').removeClass('open');
            }

            if (!site__filter.is(e.target) && site__filter.has(e.target).length === 0) {
                site__filter.find('ul').removeClass('open');
            }

            if (!site__sort.is(e.target) && site__sort.has(e.target).length === 0) {
                site__sort.find('ul').removeClass('open');
            }
        });

        /*  [ Slick Slider ]
        - - - - - - - - - - - - - - - - - - - - */

        // Place Grid Slider

        // var place_slider = $('.place-slider'),
        //     place_grid = $('.place-slider__grid');

        // place_grid.slick({
        //     centerMode: true,
        //     centerPadding: '418px',
        //     slidesToShow: 1,
        //     dots: true,
        //     prevArrow: $('.place-slider__prev'),
        //     nextArrow: $('.place-slider__next'),
        //     responsive: [
        //         {
        //             breakpoint: 992,
        //             settings: {
        //                 slidesToShow: 3,
        //                 slidesToScroll: 3,
        //                 centerPadding: 0,
        //             }
        //         },
        //         {
        //             breakpoint: 575,
        //             settings: {
        //                 slidesToShow: 1,
        //                 slidesToScroll: 1,
        //                 centerPadding: 0,
        //                 arrows: false,
        //                 prevArrow: '',
        //                 nextArrow: '',
        //             }
        //         },
        //     ]
        // });

        // place_slider.find('.place-slider__prev').hide();

        // place_grid.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        //     if (currentSlide == 0) {
        //         place_slider.find('.place-slider__prev').hide();
        //     } else {
        //         place_slider.find('.place-slider__prev').show();
        //     }
        // });

        // // Slick Slider

        // $('.slick-sliders').each(function () {
        //     var item = $(this).find('.slick-slider').data('item'),
        //         arrows = $(this).find('.slick-slider').data('arrows'),
        //         autoplay = $(this).find('.slick-slider').data('autoplay'),
        //         cssEase = $(this).find('.slick-slider').data('cssease'),
        //         fade = $(this).find('.slick-slider').data('fade'),                
        //         itemScroll = $(this).find('.slick-slider').data('itemscroll'),
        //         dots = $(this).find('.slick-slider').data('dots'),
        //         infinite = $(this).find('.slick-slider').data('infinite'),
        //         centerMode = $(this).find('.slick-slider').data('centermode'),
        //         centerPadding = $(this).find('.slick-slider').data('centerpadding'),
        //         smallpcItem = $(this).find('.slick-slider').data('smallpcitem'),
        //         smallpcScroll = $(this).find('.slick-slider').data('smallpcscroll'),
        //         smallpcPadding = $(this).find('.slick-slider').data('smallpcpadding'),
        //         tabletItem = $(this).find('.slick-slider').data('tabletitem'),
        //         tabletScroll = $(this).find('.slick-slider').data('tabletscroll'),
        //         tabletPadding = $(this).find('.slick-slider').data('tabletpadding'),
        //         mobileItem = $(this).find('.slick-slider').data('mobileitem'),
        //         mobileScroll = $(this).find('.slick-slider').data('mobilescroll'),
        //         mobilearrows = $(this).find('.slick-slider').data('mobilearrows'),
        //         mobilepadding = $(this).find('.slick-slider').data('mobilepadding'),
        //         asNavFor = $(this).find('.slick-slider').data('asNavFor'),
        //         slick_slider = $(this).find('> .slick-slider'),
        //         _this = $(this);


        //     slick_slider.slick({
        //         centerMode: centerMode,
        //         centerPadding: centerPadding,
        //         dots: dots,                
        //         autoplay: autoplay,
        //         fade: fade,
        //         cssEase: cssEase,
        //         infinite: infinite,
        //         speed: 500,
        //         arrows: arrows,
        //         slidesToShow: item,
        //         slidesToScroll: itemScroll,
        //         prevArrow: $(this).find('.place-slider__prev'),
        //         nextArrow: $(this).find('.place-slider__next'),
        //         asNavFor: asNavFor,
        //         responsive: [
        //             {
        //                 breakpoint: 1200,
        //                 settings: {
        //                     slidesToShow: smallpcItem,
        //                     slidesToScroll: smallpcScroll,
        //                     centerPadding: smallpcPadding,
        //                 }
        //             },
        //             {
        //                 breakpoint: 992,
        //                 settings: {
        //                     slidesToShow: tabletItem,
        //                     slidesToScroll: tabletScroll,
        //                     centerPadding: tabletPadding,
        //                 }
        //             },
        //             {
        //                 breakpoint: 575,
        //                 settings: {
        //                     slidesToShow: mobileItem,
        //                     slidesToScroll: mobileScroll,
        //                     arrows: mobilearrows,
        //                     centerPadding: mobilepadding,
        //                     prevArrow: '',
        //                     nextArrow: '',
        //                 }
        //             },
        //         ]
        //     });

        //     _this.find('.place-slider__prev').hide();

        //     slick_slider.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        //         if (currentSlide == 0) {
        //             _this.find('.place-slider__prev').hide();
        //         } else {
        //             _this.find('.place-slider__prev').show();
        //         }
        //     });
        // });

        // $('.testimonial-control').slick({
        //     slidesToShow: 3,
        //     slidesToScroll: 1,
        //     asNavFor: '.testimonial-slider',
        //     dots: false,
        //     centerMode: true,
        //     focusOnSelect: true,
        // });

        // // City Slider

        // $('.city-slider').each(function () {

        //     var city = $(this),
        //         city_grid = city.find('.city-slider__grid');

        //     city_grid.slick({
        //         dots: false,
        //         infinite: false,
        //         speed: 300,
        //         slidesToShow: 4,
        //         slidesToScroll: 4,
        //         prevArrow: city.find('.city-slider__prev'),
        //         nextArrow: city.find('.city-slider__next'),
        //         responsive: [
        //             {
        //                 breakpoint: 992,
        //                 settings: {
        //                     slidesToShow: 2,
        //                     slidesToScroll: 2,
        //                 }
        //             },
        //             {
        //                 breakpoint: 415,
        //                 settings: {
        //                     slidesToShow: 1,
        //                     slidesToScroll: 1
        //                 }
        //             },
        //         ]
        //     });

        //     city.find('.city-slider__prev').hide();

        //     city_grid.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        //         if (currentSlide == 0) {
        //             city.find('.city-slider__prev').hide();
        //         } else {
        //             city.find('.city-slider__prev').show();
        //         }
        //     });
        // });

        /*  [ Tabs ]
        - - - - - - - - - - - - - - - - - - - - */
        $( '.menu-tab li a' ).on( 'click', function(e) {
            e.preventDefault();
            var tab = $( this ).attr( 'href' );
            $( '.menu-tab li' ).removeClass( 'active' );
            $( this ).parents( 'li' ).addClass( 'active' );
            $( '.menu-wrap' ).removeClass( 'active' );
            $( tab ).addClass( 'active' );
        });


        /*  [ Add Socials ]
        - - - - - - - - - - - - - - - - - - - - */
        $( '.add-social' ).on( 'click', function(e) {
            e.preventDefault();
            var id = $( this ).attr( 'href' ),
                clone = $( id ).find( '.field-clone' ).html();
            $( id ).find( '.socials-list' ).append(clone);
        });
        $(".socials-list").bind("DOMSubtreeModified", function() {
            $('.remove-social').on('click', function (e){
                e.preventDefault();
                $(this).parents( '.field-3col' ).remove();
            });
        });

        /*  [ Quantity ]
        - - - - - - - - - - - - - - - - - - - - */
        $('.shop-details__quantity').each(function () {
            var minus = $(this).find('.minus'),
                plus = $(this).find('.plus');
            minus.on('click', function () {
                var qty = $(this).parent().find('.qty');
                if (qty.val() <= 1) {
                    qty.val(1);
                } else {
                    qty.val((parseInt(qty.val(), 10) - 1));
                }
            });
            plus.on('click', function () {
                var qty = $(this).parent().find('.qty');
                qty.val((parseInt(qty.val(), 10) + 1));
            });
        });

        /*  [ Isotope ]
        - - - - - - - - - - - - - - - - - - - - */
        $(window).on('load', function () {
            var isotope__grid = $('.isotope__grid');
            isotope__grid.each(function () {
                isotope__grid.isotope({
                    // options
                    itemSelector: '.isotope__grid__item',
                    layoutMode: 'fitRows'
                });

                $('.isotope__nav a').on('click', function (e) {
                    e.preventDefault();
                    $(this).addClass('active');
                    $('.isotope__nav a').not(this).removeClass('active');
                    var selector = $(this).attr('data-filter');
                    isotope__grid.isotope({filter: selector});
                    return false;
                });
            });
            if ($( window ).width() > 992 || $( window ).width() < 575 ) {
                $( '.place-item.layout-02 .place-thumb' ).each( function() {
                    var pi_w = $( this ).outerWidth();
                    $( this ).find( '.place-thumb-slider' ).css( 'width', pi_w );
                });
            }
        });

        /*  [ Chosen ]
        - - - - - - - - - - - - - - - - - - - - */
        // $('.chosen-select').chosen({
        //     no_results_text: "Oops, nothing found!"
        // });

        /*  [ Filter Show more ]
        - - - - - - - - - - - - - - - - - - - - */
        $( '.open-more' ).on( 'click', function(e) {
        	e.preventDefault();
        	var close = $( this ).data( 'close' );
        	var more = $( this ).data( 'more' );
        	$( this ).parents( '.filter-list' ).toggleClass( 'open' );
        	if ($( this ).parents( '.filter-list' ).hasClass('open')) {
        		$( this ).text( close );
        	} else {
				$( this ).text( more );
        	}
        });

        /*  [ Opening Hours ]
        - - - - - - - - - - - - - - - - - - - - */
        $('.accordion-bot').hide();
        $('.accordion-top label').on('click', function (e) {
            e.preventDefault();
            var _this = $(this);

            if ($(this).find('input').is(':checked')) {

                console.log("is unchecked");
                // _this.find('.accordion-top > span').text("Closed111");

                $(this).find('input').prop('checked', false);
            } else {

                console.log("is checked");
                // _this.find('.accordion-top > span').text("Closed222");

                $(this).find('input').prop('checked', 'checked');
            }
            // $('.accordion-top label').not(this).parents('.accordion-top').next().slideUp().parents('.accordion-top').removeClass('open');
            // $('.accordion-top label').not(this).parents('.accordion-top').find('input').prop('checked', false);
            $(this).parents('.accordion-top').next().slideToggle().parent().toggleClass('open');
        });

        $('.accordion-item').each(function () {
            var _this = $(this);
            _this.find('select').change(function () {
                var open_first = _this.find('select[class="open_time"] option:first-child').text();
                var close_first = _this.find('select[class="close_time"] option:first-child').text();
                var open_time = _this.find('select[class="open_time"] option:selected').text();
                var close_time = _this.find('select[class="close_time"] option:selected').text();
                if ((open_time != open_first) && (close_time != close_first)) {
                    _this.addClass('active');
                    // _this.find( '.accordion-bot' ).slideUp();
                    _this.find('.accordion-top > span').text(open_time + ' - ' + close_time);
                }
            });
        });

        $( '.faqs-accordion h4' ).on( 'click', function(e) {
            e.preventDefault();
            $( '.faqs-accordion li h4' ).not( this ).parents( 'li' ).removeClass( 'active' );
            $( this ).parents( 'li' ).toggleClass( 'active' );
            $( '.faqs-accordion li h4' ).not( this ).parents( 'li' ).find( '.desc' ).slideUp();
            $( this ).parents( 'li' ).find( '.desc' ).slideToggle();
        });

        $( '.toggle-show' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parent().find( '.toggle-list' ).slideToggle();
        });

        $(document).mouseup(function(e) {
            var container = $(".toggle-select");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) 
            {
                container.find( '.toggle-list' ).slideUp();
            }
        });

        /*  [ Input File ]
		- - - - - - - - - - - - - - - - - - - - */
        function readURL(input, _this) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
        
                reader.onload = function (e) {
                    _this.parents( '.field-group' ).find( '.img_preview' ).attr('src', e.target.result);
                }
                reader.readAsDataURL(input.files[0]);
            }
        }
        
        $( '.field-group input[type="file"]' ).change(function () {
        	var _this = $( this );
            readURL(this, _this);
        });
        
        var fileInput = $('.upload-file');
        var maxSize = fileInput.data('max-size');
        $('.upload-form').submit(function(e){
            if(fileInput.get(0).files.length){
                var fileSize = fileInput.get(0).files[0].size; // in bytes
                if(fileSize>maxSize){
                    alert('file size is more then' + maxSize + ' bytes');
                    return false;
                }else{
                    alert('file size is correct- '+fileSize+' bytes');
                }
            }else{
                alert('choose file, please');
                return false;
            }
        });

        /*  [ Footer Fixed ]
        - - - - - - - - - - - - - - - - - - - - */
        $( '.business-landing-banner' ).each( function() {
            var height = $( this ).outerHeight();
            $( '.footer' ).css( 'margin-bottom', height );
        });

        /*  [ Scroll ]
        - - - - - - - - - - - - - - - - - - - - */
        $(window).scroll(function () {
            if ($(window).scrollTop() > $('.site-header').height()) {
                $('.listing-nav').addClass('on_scroll');
            } else {
                $('.listing-nav').removeClass('on_scroll');
            }
            if ($(window).scrollTop() + window.innerHeight >= $('#footer').offset().top) {
                $('.listing-nav').addClass('is_footer');
            } else {
                $('.listing-nav').removeClass('is_footer');
            }
        });

        $('.nav-scroll a[href^="#"]').on('click', function (event) {

            var target = $(this.getAttribute('href'));
            var top = null;

            if (target.length) {
                if ($(window).width() > 767) {
                    top = target.offset().top - 15;
                } else {
                    top = target.offset().top - 15;
                }
                event.preventDefault();
                $('html, body').stop().animate({
                    scrollTop: top
                }, 800);
            }

            $('.nav-scroll a').not($(this)).parent().removeClass('active');
            $(this).parent().addClass('active');
        });

        $(window).scroll(function () {
            var scrollDistance = $(window).scrollTop();

            // Assign active class to nav links while scolling
            $('.listing-box').each(function (i) {
                if ($(this).position().top <= scrollDistance + 150) {
                    var href = $(this).attr('id'),
                        id = '#' + href;
                    $('.nav-scroll a').parent().removeClass('active');
                    $('.nav-scroll a').each(function () {
                        var attr = $(this).attr('href');
                        // For some browsers, `attr` is undefined; for others, `attr` is false. Check for both.
                        if (attr == id) {
                            // Element has this attribute
                            $(this).parent().addClass('active');
                        }
                    });
                }
            });
        });

        $('#clear').fadeOut(0);
        $('.field-pin a').on('click', function (e) {
            e.preventDefault();
            $(this).fadeOut(0);
            $('.field-pin a').not(this).fadeIn(0);
        });
        // $('.btn-mapsview').on('click', function () {
        //     var href = $(this).attr('href');
        //     window.location.href = href;
        // });

        // Show More Description
        $('.show-more').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.place__box-overview').find('.place__desc').addClass('open');
            $(this).fadeOut(0);
        });

        $( '.menu-more' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( '.menu-wrap' ).find( '.flex' ).addClass('open');
            $(this).fadeOut(0);
        });

        $('.stars a').on('click', function (e) {
            e.preventDefault();
            $('.stars a').not(this).removeClass('active');
            $(this).addClass('active');
            $(this).parents('.stars').addClass('selected');
        });

        /*  [ Popup ]
        - - - - - - - - - - - - - - - - - - - - */
        $('.open-popup').on('click', function (e) {
            e.preventDefault();
            var id = $(this).attr('href');
            $(this).parents('body').find(id).fadeIn();
            if ($(id).find('.popup').outerHeight() > $(window).outerHeight()) {
                $(id).addClass('more-height');
                $('body').css('overflow-y', 'hidden');

            } else {
                $(id).removeClass('more-height');
                $('body').css('overflow-y', 'auto');
            }
            $(this).parents('.popup-wrap').fadeOut();
        });
        $('.popupbg-close, .popup-close').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.popup-wrap').fadeOut();
        });       

        /*  [ Accordion ]
        - - - - - - - - - - - - - - - - - - - - */
        $('.accordion.first-open > li:first-child').addClass('open');
        $('.accordion-title a').on('click', function (event) {
            event.preventDefault();
            if ($(this).parents('li').hasClass('open')) {
                var ac = $(this).parents('li');
                ac.removeClass('open');
                ac.find('.accordion-content').slideUp(400);
            } else {
                var acc = $(this).parents('.accordion');
                var acd = acc.find('.accordion-content');
                var act = acd.not($(this).parents('li').find('.accordion-content'));
                act.slideUp(400);
                acc.find('> li').not($(this).parents('li')).removeClass('open');
                var al = $(this).parents('li');
                al.addClass('open').find('.accordion-content').slideDown(400);
            }
        });

        function readURL1(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('#member_avatar').attr('src', e.target.result);
                }
                reader.readAsDataURL(input.files[0]);
            }
        }

        $( '.open-wg' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( '.sidebar' ).toggleClass( 'open' );
            $( this ).parents( '.sidebar' ).find( '.widget' ).toggleClass( 'open' );
        });
        $(document).mouseup(function(e) 
        {
            var container = $( '.widget' );

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) 
            {
                container.removeClass( 'open' );
                container.parents( '.sidebar' ).removeClass( 'open' );
            }
        });

        var fixmeTop = null;

        if ($('.sidebar').offset()) {
            fixmeTop = $('.sidebar').offset().top;
        }

        var lastScrollTop = 0;

        $(window).scroll(function() {                  // assign scroll event listener

            var currentScroll = $(window).scrollTop(); // get current position

            if (currentScroll >= fixmeTop - 40) {           // apply position: fixed if you
                $('.sidebar').addClass( 'fixed' );
            } else {                                   // apply position: static
                $('.sidebar').removeClass( 'fixed' );
            }

            var st = $(this).scrollTop();
            // if (st > lastScrollTop){
            //     $( '.widget-reservation-mini' ).addClass( 'open' );
            // } else {
            //     $( '.widget-reservation-mini' ).removeClass( 'open' );
            // }
            lastScrollTop = st;

        });


        if ($('.sidebar').length) {
            fixmeTop = $('.sidebar').offset().top;       // get initial position of the element
            $(window).scroll(function () {                  // assign scroll event listener
                var currentScroll = $(window).scrollTop(); // get current position
                if (currentScroll >= fixmeTop - 40) {           // apply position: fixed if you
                    $('.sidebar').addClass('fixed');
                } else {                                   // apply position: static
                    $('.sidebar').removeClass('fixed');
                }
            });
        }

        $( '.mb-open' ).on( 'click', function(e) {
            e.preventDefault();
            var id = $( this ).attr( 'href' );
            $( this ).parents( '.archive-city' ).find( '.archive-filter ' ).fadeIn();
            $( id ).fadeIn();
        });

        $( '.mb-maps a' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( '.archive-city' ).find( '.col-right' ).fadeIn();
        });

        $( '.close-maps' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( '.col-right' ).fadeOut();
        });

        $('.member-avatar input[type="file"]').change(function () {
            readURL1(this);
        });

        $('.member-place-list').each(function () {
            $("#all").on('click', function () {
                $('input:checkbox').not(this).prop('checked', this.checked);
            });
        });

        $('.open-option').on('click', function (e) {
            e.preventDefault();
            $(this).parent().find('.box-option').fadeToggle();
        });

        $(document).mouseup(function (e) {
            var container = $('.box-option');

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.fadeOut();
            }
        });

        $('.account>a').on('click', function (e) {
            e.preventDefault();
            $(this).parent().find('.account-sub').fadeToggle();
        });

        $(document).mouseup(function (e) {
            var container = $('.account');

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.find('.account-sub').fadeOut();
            }
        });

        if ($('.city-content__tabtitle').length) {
            var distance = $('.city-content__tabtitle').offset().top;

            $(window).scroll(function () {

                if ($(window).scrollTop() >= distance) {
                    $('.city-content').addClass("affix");

                } else {
                    $('.city-content').removeClass("affix");
                }
            });
        }

        // Init empty gallery array
        var container = [];

        // Loop over gallery items and push it to the array
        $('.photoswipe').find('.photoswipe-item').each(function () {
            var $link = $(this).find('a'),
                item = {
                    src: $link.attr('href'),
                    w: $link.data('width'),
                    h: $link.data('height'),
                    title: $link.data('caption')
                };
            container.push(item);
        });

        $( '.venobox' ).each( function() {
            $( this ).venobox();
        });

        // Define click event on gallery item
        $('.show-gallery').click(function (event) {
            // Prevent location change
            event.preventDefault();
            $( this ).parents( '.place-single-sliders' ).find( '.slick-slider .venobox' ).trigger( 'click' );
        });


        // Filter

        $( '.filter-box h3' ).on( 'click', function() {
            $( this ).toggleClass( 'active' );
            $( this ).parents( '.filter-box' ).find( '.filter-list' ).slideToggle();
        });

        $( '.filter-control .current' ).on( 'click', function() {
            $( this ).parent().toggleClass( 'active' );
        });

        $( '.list li' ).on( 'click', function() {
        	var label = $( this ).text();
        	$( '.list li' ).not( this ).removeClass( 'selected' );
        	$( this ).addClass( 'selected' );
        	$( this ).parents( '.select-group' ).find( '.clear-filter' ).fadeIn(0);
        	$( this ).parents( '.filter-control' ).removeClass( 'active' );
            $( this ).parents( '.filter-control' ).find( '> span' ).text( label );
            $( this ).parents( '.select-box' ).find( 'select option["' + label + '"]' ).attr( 'selected', 'selected' );
        });

        $( '.clear-filter' ).on( 'click', function() {
        	$( this ).removeClass( 'open' );
        	$( this ).parents( '.select-group' ).find( '.select-box' ).each( function() {
        		var df = $( this ).find( 'select > option:first-child' ).text();
        		$( this ).find( 'select > option' ).removeClass( 'selected' );
        		$( this ).find( 'span.current' ).text( df );
        		$( this ).find( '.list li' ).removeClass( 'selected' );
        	});
        });

        $( '.close-filter' ).on( 'click', function() {
            $( this ).parents( 'form' ).fadeOut();
            $( this ).parents( '.archive-filter' ).fadeOut();
        });

        $( '.place-layout a' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( '.main-primary' ).find( '.place-item' ).addClass( 'skeleton-loading' );
            var type = $( this ).data( 'layout' );
            var type_remove = $( '.place-layout a' ).not( this ).data( 'layout' );
            $( '.place-layout a' ).not( $( this ) ).removeClass( 'active' );
            $( this ).addClass( 'active' );
            $( this ).parents( '.site-main' ).addClass( type );
            $( this ).parents( '.site-main' ).removeClass( type_remove );
            setTimeout( function() {
                $( '.main-primary' ).find( '.place-item' ).removeClass( 'skeleton-loading' );
            }, 1500);
        });

        $( '.icon-toggle' ).on( 'click', function() {
        	$( this ).toggleClass( 'active' );
        	if ($( this ).hasClass( 'active' )) {
                $( this ).parents( 'body' ).find( '.col-right' ).fadeOut(0);
                var ctn = $( this ).parents( 'body' ).find( '.container' ).width();
                $( this ).parents( 'body' ).find( '.col-left' ).css( 'width', ctn );
                $( this ).parents( 'body' ).find( '.col-left' ).addClass( 'no-maps' );
                $( this ).parents( 'body' ).find( '.archive-city.layout-02' ).addClass( 'active' );
        	} else {
        		$( this ).parents( 'body' ).find( '.col-right' ).fadeIn(0);
                $( this ).parents( 'body' ).find( '.archive-city.layout-02' ).removeClass( 'active' );
                $( this ).parents( 'body' ).find( '.col-left' ).css( 'width', 'auto' );
                $( this ).parents( 'body' ).find( '.col-left' ).removeClass( 'no-maps' );
        	}
        });

        if ($(window).width() > 992) {
            $( '.archive-city.layout-02' ).each( function() {
            	var ctn = $( this ).parents( 'body' ).find( '.container' ).width();
            	var ww = $( window ).width();
            	var w_left = (ww - ctn) / 2;
            	$( this ).find( '.col-left' ).css( 'padding-left', w_left );
            });
        }

        if ($(window).width() < 576) {
            $( '.cuisine-slider .item img' ).each( function() {
                var ctn = $( this ).width();
                $( this ).css( 'height', ctn );
            });
        }

        $(document).mouseup(function(e) 
        {
            var container = $("ul.list");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) 
            {
                container.parents( '.filter-control' ).removeClass( 'active' );
            }
        });

        $( '.filter-box' ).each( function() {
        	$( '.field-check input[type="checkbox"]' ).change( function() {
        		if ($( this ).is(':checked')) {
        			$( this ).parents( 'form' ).find( '.clear-filter' ).addClass( 'open' );
        		} else if( $( '.field-check input[type="checkbox"]' ).not( this ).is(':checked') ){
        			$( this ).parents( 'form' ).find( '.clear-filter' ).addClass( 'open' );
        		} else if( $( '.field-check input[type="checkbox"]' ).not( this ).prop( 'checked' ) == false ){
        			$( this ).parents( 'form' ).find( '.clear-filter' ).removeClass( 'open' );
        		}
        	});
        });

        $( '.clear-filter' ).on( 'click', function(e) {
        	e.preventDefault();
        	$( this ).removeClass( 'open' );
        	$( this ).parents( 'form' ).find( 'input[type="checkbox"]' ).prop( 'checked', false );
        });


        // $('.lity-btn').on('click', '[data-lightbox]', lity);

        // $('.share').on('click', function (e) {
        //     e.preventDefault();
        //     $(this).parent().find('.social-share').slideToggle();
        // });

        $(document).mouseup(function (e) {
            var container = $(".place-share");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.find('.social-share').slideUp();
            }
        });

        $( '.place-share .share' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( '.place-share' ).find( '.social-share' ).fadeToggle();
        });

        $('.field-select.has-sub input').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.field-select').find('.field-sub').fadeToggle();
        });
        $(document).mouseup(function (e) {
            var container = $(".field-select.has-sub");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.find('.field-sub').hide();
            }
        });

        // Datepicker
        $('.datepicker').each( function() {
            $( this ).datetimepicker({
                timepicker:false,
                format:'d.m.Y',
            });
        });

        $('body').on('click', '.datepicker', function() {
            $(this).parent().find('.xdsoft_datetimepicker').addClass('on');
            var width = $('.datepicker').outerWidth();
            $('.xdsoft_datetimepicker').width(width - 10);
        });

        $('body').on('click', '.timepicker', function() {
            $(this).parent().find('.xdsoft_timepicker').addClass('on');
            var width = $('.timepicker').outerWidth();
            $('.xdsoft_datetimepicker').width(width - 10);
        });

        
        var date_width = $('.datepicker').outerWidth();
        $('.gj-picker-bootstrap').css('width', date_width);

        $('.field-time .field-sub li a').on('click', function (e) {
            e.preventDefault();
            var text = $(this).text();
            $(this).parents('.field-time').find('input').val(text);
        });

        $('.shop-details__quantity span').on('click', function (e) {
            e.preventDefault();
            var text = [];
            if ($('.number_adults').val() > 0) {
                text.push('Adults ' + $('.number_adults').val());
            }
            if ($('.number_childrens').val() > 0) {
                text.push(' Childrens ' + $('.number_childrens').val());
            }
            $(this).parents('.field-guest').find('input[type="text"]').val(text.toString());
        });

        /*  [ Close ]
        - - - - - - - - - - - - - - - - - - - - */
        $( '.close' ).on( 'click', function(e) {
            e.preventDefault();
            var close = $( this ).data( 'close' );
            $( '.' + close ).fadeOut();
        });

        /*  [ Delete ]
        - - - - - - - - - - - - - - - - - - - - */
        $( '.clear-all' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( '.ob-item' ).find( '.ob-content > *' ).remove();
            $( this ).parents( '.ob-item' ).find( 'h3 span' ).text( '(0)' );
        });
        $( '.delete-noti' ).on( 'click', function(e) {
            e.preventDefault();
            var number = $( this ).parents( '.ob-item' ).find( 'h3 span' ).text();
            number = number.replace("(", "");
            number = number.replace(")", "");
            number = number - 1;
            $( this ).parents( '.ob-item' ).find( 'h3 span' ).text( '(' + number + ')' );
            $( this ).parents( '.noti-item' ).remove();
        });

        $( '.delete' ).on( 'click', function(e) {
            e.preventDefault();
            $( this ).parents( 'tr' ).remove();
        });

        // Search Suggestion

        $( '.open-suggestion' ).on( 'focus', function(e) {
            e.preventDefault();
            $( this ).parent().find( '.search-suggestions' ).fadeIn();
        });
        $( '.open-suggestion' ).on( 'blur', function(e) {
            e.preventDefault();
            $( this ).parent().find( '.search-suggestions' ).fadeOut();
        });
        $( '.search-suggestions a' ).on( 'click', function(e) {
            e.preventDefault();
            var text = $( this ).find( 'span' ).text();
            $( this ).parents( '.field-input' ).find( 'input' ).val(text);
            $( this ).parents( '.search-suggestions' ).fadeOut();
        });

        $( '.select-type' ).on( 'click', function(e) {
            e.preventDefault();
            var type = $( this ).data( 'type' );
            var type_remove = $( '.top-area .select-type' ).not( this ).data( 'type' );
            $( '.select-type' ).removeClass( 'active' );
            $( this ).parents( '.site-main' ).addClass( type );
            $( this ).parents( '.site-main' ).removeClass( type_remove );
            $( this ).addClass( 'active' );
            if (type == 'layout-list') {
                $('.place-thumb-slider').slick({
                    dots: true,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                });
            } else {
                $('.place-thumb-slider').slick('unslick');
            }
            if ($( window ).width() > 992 || $( window ).width() < 575 ) {
                $( '.place-item.layout-02 .place-thumb' ).each( function() {
                    var pi_w = $( this ).outerWidth();
                    $( this ).find( '.place-thumb-slider' ).css( 'width', pi_w );
                });
            }

        });

        // $( window ).load( function() {
        //     $('.cuisine-slider .hover-img').each(function() {
        //         var x = $( this ).find( 'img' ).outerWidth();
        //         $( this ).find( 'img' ).css( 'height', x + 'px' );
        //         $( this ).find( 'img' ).css( 'width', x + 'px' );
        //     });
        // });

        
    });
});

$(function(){
    $.fn.serializeObject = function(){

        var self = this,
            json = {},
            push_counters = {},
            patterns = {
                "validate": /^[a-zA-Z][a-zA-Z0-9_]*(?:\[(?:\d*|[a-zA-Z0-9_]+)\])*$/,
                "key":      /[a-zA-Z0-9_]+|(?=\[\])/g,
                "push":     /^$/,
                "fixed":    /^\d+$/,
                "named":    /^[a-zA-Z0-9_]+$/
            };


        this.build = function(base, key, value){
            base[key] = value;
            return base;
        };

        this.push_counter = function(key){
            if(push_counters[key] === undefined){
                push_counters[key] = 0;
            }
            return push_counters[key]++;
        };

        $.each($(this).serializeArray(), function(){

            // Skip invalid keys
            if(!patterns.validate.test(this.name)){
                return;
            }

            var k,
                keys = this.name.match(patterns.key),
                merge = this.value,
                reverse_key = this.name;

            while((k = keys.pop()) !== undefined){

                // Adjust reverse_key
                reverse_key = reverse_key.replace(new RegExp("\\[" + k + "\\]$"), '');

                // Push
                if(k.match(patterns.push)){
                    merge = self.build([], self.push_counter(reverse_key), merge);
                }

                // Fixed
                else if(k.match(patterns.fixed)){
                    merge = self.build([], k, merge);
                }

                // Named
                else if(k.match(patterns.named)){
                    merge = self.build({}, k, merge);
                }
            }

            json = $.extend(true, json, merge);
        });

        return json;
    };
});