import Vue from 'vue'
import VueRouter from 'vue-router';
import {protectedPage} from '@/utils';

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */'../views/Home')
  },
  {
    path: '/parceiro/:slug?',
    name: 'partner-details',
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/PartnerDetails')
  },
  {
    path: '/resultado-busca/:cityCode/:citySlug/:term',
    name: 'search-old',
    component: () => import(/* webpackChunkName: "home" */'../views/Search')
  },
  {
    path: '/busca/:citySlug?/:term?',
    name: 'search-list',
    component: () => import(/* webpackChunkName: "home" */'../views/Search')
  },
  {
    path: '/categoria/:categorySlug?',
    name: 'category-list',
    component: () => import(/* webpackChunkName: "home" */'../views/Search')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "home" */'../views/Blog')
  },
  {
    path: '/blog/:slug?',
    name: 'blog-details',
    component: () => import(/* webpackChunkName: "home" */'../views/Blog/Detail')
  },
  {
    path: '/politica-de-privacidade',
    name: 'politics',
    component: () => import(/* webpackChunkName: "home" */'../views/Politics')
  },
  {
    path: '/sobre-disk-certo-digital',
    name: 'about',
    component: () => import(/* webpackChunkName: "home" */'../views/About')
  },
  {
    path: '/fale-conosco',
    name: 'contact',
    component: () => import(/* webpackChunkName: "home" */'../views/Contact')
  },
  {
    path: '/central-de-atendimento',
    name: 'call-center',
    component: () => import(/* webpackChunkName: "home" */'../views/CallCenter')
  },
  {
    path: '/planos-empresariais',
    name: 'plans',
    component: () => import(/* webpackChunkName: "home" */'../views/Plan')
  },
  {
    path: '/p/login',
    name: 'partner-login',
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Login')
  },
  {
    path: '/p/dashboard',
    name: 'partner-dashboard',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Restrict')
  },
  {
    path: '/p/meus-leads',
    name: 'partner-leads',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next, 'ads-leads');
    },
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Restrict/Leads')
  },
  {
    path: '/p/meus-reviews',
    name: 'partner-reviews',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Restrict/Reviews')
  },
  {
    path: '/p/meus-produtos',
    name: 'partner-products',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next, 'ads-hotsite');
    },
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Restrict/Products')
  },
  {
    path: '/p/meus-produtos/novo',
    name: 'partner-product-create',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next, 'ads-hotsite');
    },
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Restrict/Products/CreateUpdate')
  },
  {
    path: '/p/meus-produtos/editar/:id',
    name: 'partner-product-update',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next, 'ads-hotsite');
    },
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Restrict/Products/CreateUpdate')
  },
  {
    path: '/p/dados-empresa',
    name: 'partner-profile',
    beforeEnter(to, from, next){      
      protectedPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Restrict/Profile')
  },
  {
    path: '/p/recuperar-senha',
    name: 'partner-recover-pass',
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Login/RecoverPass')
  },
  {
    path: '/p/dashboard',
    name: 'partner-dashboard',
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/PartnerDetails')
  },
  {
    path: '/cadastro-empresa',
    name: 'partner-register',
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Register')
  },
  {
    path: '/cadastro-sucesso',
    name: 'partner-success',
    component: () => import(/* webpackChunkName: "home" */'../views/Partner/Register/Success.vue')
  },
  {
    path: '/seja-representante',
    name: 'reseller',
    component: () => import(/* webpackChunkName: "home" */'../views/Reseller')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */'../views/404'),
  },
]

// const routes = baseRoutes.concat(userRoutes, cartRoutes, productsRoutes, ordersRoutes, searchRoutes);
const routes = baseRoutes;

const router = new VueRouter({
  mode: 'history',  
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router;