import '@babel/polyfill'
// import 'mutationobserver-shim'

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import mixin from './mixins';

// plugins
import './plugins';
import axios from 'axios';

Vue.config.productionTip = false

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('disk_token');
   
  config.headers.Authorization =  token;
 
   return config;
});


new Vue({
	router,
	store,  
	mixins: [mixin],
	render: h => h(App),
}).$mount('#app')
