import { render, staticRenderFns } from "./HeaderFixed.vue?vue&type=template&id=10a54248&scoped=true&"
import script from "./HeaderFixed.vue?vue&type=script&lang=js&"
export * from "./HeaderFixed.vue?vue&type=script&lang=js&"
import style0 from "./HeaderFixed.vue?vue&type=style&index=0&id=10a54248&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a54248",
  null
  
)

export default component.exports