<template>
    <div>
        <header id="header" class="site-header">
            <div class="container-fluid">
                <!--  -->
                <div class="row">
                    <div class="col-sm-10 col-lg-3">
                        <div class="site" style="display: flex">
                            <div class="site__menu">
                                <a title="Menu Icon" href="#" class="site__menu__icon">
                                    <i class="las la-bars la-24-black"></i>
                                </a>
                                <div class="popup-background"></div>
                                <div class="popup popup--left">
                                    <a title="Close" href="#" class="popup__close">
                                        <i class="las la-times la-24-black"></i>
                                        Fechar
                                    </a><!-- .popup__close -->
                                    <div class="popup__content">
                                        <div class="popup__user popup__box open-form">
                                            
                                        </div><!-- .popup__user -->
                                        <div class="popup__destinations popup__box p-12">
                                            <ul class="menu-arrow">
                                                <li>
                                                    <a title="Página Inicial" href="/">Página Inicial </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="popup__destinations popup__box p-12">
                                            <ul class="menu-arrow">
                                                <li>
                                                    <a title="Quem Somos" :href="$root.getUrlPath('about')">Quem Somos</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="popup__destinations popup__box p-12">
                                            <ul class="menu-arrow">
                                                <li>
                                                    <a title="Planos Empresariais" :href="$root.getUrlPath('plans')">Planos Empresariais</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="popup__destinations popup__box p-12">
                                            <ul class="menu-arrow">
                                                <li>
                                                    <a title="Planos Call Center" :href="$root.getUrlPath('call-center')">Planos Call Center</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div><!-- .popup__content -->
                                    <div class="popup__button popup__box p-12">
                                        <a title="Add place" :href="$root.getUrlPath('partner-register')" class="btn" style="width: 100%">
                                            <i class="la la-plus"></i>
                                            <span>Publicar minha empresa</span>
                                        </a>
                                    </div>
                                    <div v-if="Object.keys(user).length == 0" class="popup__button popup__box p-12">
                                        <a title="Add place" href="add-place.html" class="btn" style="background: #0098da; width: 100%">
                                            <i class="las la-user la-24-white"></i>
                                            <span>Conectar</span>
                                        </a>
                                    </div>                                
                                    <div v-else class="popup__button popup__box p-12">
                                        <a title="Add place" :href="$root.getUrlPath('partner-dashboard')" class="btn" style="background: #0098da; width: 100%">
                                            <i class="las la-user la-24-white"></i>
                                            <span>Olá, {{ user.first_name }}</span>
                                        </a>
                                    </div>                                
                                </div><!-- .popup -->
                            </div><!-- .site__menu -->
                            
                            <template v-if="$route.name == 'home'">
                                <div class="site__brand">
                                    <a title="Logo" href="/" class="site__brand__logo"><img src="@/assets/images/assets/logo.png" alt="Disk Certo Digital Logo"></a>
                                </div>    
                            </template>
                            <template v-if="$route.name != 'home'">
                                <div class="site__brand">
                                    <a title="Logo" href="/" class="site__brand__logo"><img src="@/assets/images/assets/logo.png" alt="Disk Certo Digital Logo"></a>
                                </div>
                                <!-- <div class="site__search layout-02 d-none d-lg-block">
                                    <a title="Close" href="#" class="search__close">
                                        <i class="la la-times"></i>
                                    </a>

                                    <form-search></form-search>
                                </div> -->
                            </template>
                        </div><!-- .site -->
                    </div><!-- .col-md-6 -->
                    <div class="col-sm-2 col-lg-9">
                        <div class="right-header align-right">
                            <nav class="main-menu">
                                <ul>
                                    <li>
                                        <a href="/" title="Inicio">Página Inicial</a>           
                                    </li>
                                    <li>
                                        <a :href="$root.getUrlPath('about')" title="Quem Somos">Quem Somos</a>
                                    </li>
                                    <li>
                                        <a :href="$root.getUrlPath('plans')" title="Planos Empresariais">Planos Empresariais</a>
                                    </li>
                                    <li>
                                        <a :href="$root.getUrlPath('call-center')" title="Planos Call Center">Planos Call Center</a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="popup popup-form">
                                <a title="Close" href="#" class="popup__close">
                                    <i class="las la-times la-24-black"></i>
                                </a><!-- .popup__close -->
                                <ul class="choose-form">
                                    <li class="nav-signup"><a title="Sign Up" href="#signup">Sign Up</a></li>
                                    <li class="nav-login"><a title="Log In" href="#login">Log In</a></li>
                                </ul>
                                <p class="choose-more">Continue with <a title="Facebook" class="fb" href="#">Facebook</a> or <a title="Google" class="gg" href="#">Google</a></p>
                                <p class="choose-or"><span>Or</span></p>
                                <div class="popup-content">
                                    <form action="#" class="form-sign form-content" id="signup">
                                        <div class="field-inline">
                                            <div class="field-input">
                                                <input type="text" placeholder="First Name" value="" name="first_name">
                                            </div>
                                            <div class="field-input">
                                                <input type="text" placeholder="Last Name" value="" name="last_name">
                                            </div>
                                        </div>
                                        <div class="field-input">
                                            <input type="email" placeholder="Email" value="" name="email">
                                        </div>
                                        <div class="field-input">
                                            <input type="password" placeholder="Password" value="" name="password">
                                        </div>
                                        <div class="field-check">
                                            <label for="accept">
                                                <input type="checkbox" id="accept" value="">
                                                Accept the <a title="Terms" href="#">Terms</a> and <a title="Privacy Policy" href="#">Privacy Policy</a>
                                                <span class="checkmark">
                                                    <i class="la la-check"></i>
                                                </span>
                                            </label>
                                        </div>
                                        <input type="submit" name="submit" value="Sign Up">
                                    </form>
                                    <form action="#" class="form-log form-content" id="login">
                                        <div class="field-input">
                                            <input type="text" placeholder="Username or Email" value="" name="user">
                                        </div>
                                        <div class="field-input">
                                            <input type="password" placeholder="Password" value="" name="password">
                                        </div>
                                        <a title="Forgot password" class="forgot_pass" href="#">Forgot password</a>
                                        <input type="submit" name="submit" value="Login">
                                    </form>
                                </div>
                            </div><!-- .popup-form -->
                            <div class="right-header__button btn">
                                <a title="Add place" :href="$root.getUrlPath('partner-register')">
                                    <i class="las la-plus la-24-white"></i>
                                    <span>Publicar minha empresa</span>
                                </a>
                            </div>
                            <div v-if="Object.keys(user).length == 0" class="right-header__button btn" style="background: #0098da">
                                <a title="Login" :href="$root.getUrlPath('partner-login')">
                                    <i class="las la-user la-24-white"></i>
                                    <span>Conectar</span>
                                </a>
                            </div>
                            <div v-else class="right-header__button btn" style="background: #0098da">
                                <a title="Login" :href="$root.getUrlPath('partner-dashboard')">
                                    <i class="las la-user la-24-white"></i>
                                    <span>Olá, {{ user.first_name }}</span>
                                </a>
                            </div>  
                        </div><!-- .right-header -->
                    </div><!-- .col-md-6 -->
                </div><!-- .row -->
            </div><!-- .container-fluid -->
        </header>

        <!-- HEADER FIXED -->
        <header-fixed />
        
    </div>
</template>
<script>    
    // import FormSearch from '@/views/Home/Banners/FormSearch';    
    import HeaderFixed from './HeaderFixed.vue';
    import {mapActions, mapGetters} from 'vuex';
    
	export default{
        components: {
            HeaderFixed
        },
        computed:{
            ...mapGetters('Partner', ['user']),
        },
		methods:{
            ...mapActions('Partner', ['getCities', 'getUser']),            
        },
        mounted(){
            this.getCities();

            if(localStorage.getItem('disk_token')){
                this.getUser();
            }
        }
	}
</script>
<style>
    .p-12{
        padding: 12px !important;
    }
</style>