import Banner from '@/api/Banner';

export default { 
  getBanners(context, payload) {
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Banner.getBannersActive(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          context.commit('setBanners', data);
          context.commit('loading', false);

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  }, 
};