export default {
	cities: state => state.cities,
	city: state => (state.city ? JSON.parse(state.city) : {}),
	loading: state => state.loading,
	partners: state => state.partners,
	partner: state => state.partner,
	ratings: state => state.ratings,
	related: state => state.related,
	lastPartners: state => state.lastPartners,
	user: state => state.user,
};