<template>	
   <form ref="formSearch" @submit.prevent="handleSubmit" class="site-banner__search layout-02 ">
      <div class="field-input">
         <label for="s">Busca:</label>
         <input class="site-banner__search__input open-suggestion" type="text" 
         name="search" 
         v-model="search"
         placeholder="Ex: restaurante,salão,mercado..." autocomplete="off" required>
         <div class="search-suggestions name-suggestions">
            <ul>
               <li v-for="(suggestion, suggestionIndex) in suggestions" :key="suggestionIndex">
               	<a @click="(search = suggestion.name)" href="#">
               		<i :class="`las la-${suggestion.icon}`"></i>
               		<span>{{suggestion.name}}</span>
               	</a>
               </li>
            </ul>
         </div>
      </div>
      <!-- .site-banner__search__input -->
      <div class="field-input">
         <label for="loca">Onde?</label>
         <input class="site-banner__search__input open-suggestion" type="text" name="city" 
         placeholder="Cidade" autocomplete="off" :value="(city ? city.name : '')" readonly="readonly">
         <div class="search-suggestions location-suggestions">
            <ul v-if="cities.length > 0" style="max-height: 200px; overflow: auto;">
              <li v-for="city in cities" :key="city.slug">
                <a @click="selectCity(city)" href="javascript:;"><span>{{ city.name }}</span></a>
              </li>
            </ul>
         </div>
      </div>
      <!-- .site-banner__search__input -->
      <div class="field-submit">
         <button type="submit"><i class="las la-search la-24-black"></i></button>
      </div>
   </form>
</template>
<script>
  import $ from 'jquery';
  import {mapGetters, mapActions} from 'vuex';
  
  export default {
    name: 'FormSearch',
    components: {       
    },
    watch: {
    	search(promise){
    		if(promise != ''){
    			$( '.open-suggestion' ).parent().find( '.search-suggestions' ).fadeOut();
    		}
    	},
    },
    computed: {
      ...mapGetters('Partner', ['cities', 'city']),
    },
    methods: {
      ...mapActions('Partner', ['selectedCity']),
      selectCity(city){        
        this.selectedCity(city);
      },
      handleSubmit(){
        const self = this;

          if (this.$refs.formSearch.checkValidity()) {
              let params = {
                term: this.search,
                citySlug: this.city.slug,
              };
              
              window.location.href = this.$root.getUrlPath('search-list', params);
          } else {
              this.$refs.formSearch.reportValidity();
          }
      },
    },
    mounted(){
    	  $( '.open-suggestion' ).on( 'focus', function(e) {
            e.preventDefault();
            $( this ).parent().find( '.search-suggestions' ).fadeIn();
        });
        $( '.open-suggestion' ).on( 'blur', function(e) {
            e.preventDefault();
            $( this ).parent().find( '.search-suggestions' ).fadeOut();
        });
        $( '.search-suggestions a' ).on( 'click', function(e) {
            e.preventDefault();
            var text = $( this ).find( 'span' ).text();
            $( this ).parents( '.field-input' ).find( 'input' ).val(text);
            $( this ).parents( '.search-suggestions' ).fadeOut();
        });

        if(!this.city){
          this.selectedCity(this.cityDefault);
        }        
    },
    data() {
        return {
        	search: '',
          cityDefault: {
            name: 'Cáceres, MT',
            slug: 'caceres',
          },
        	suggestions: [
        		{
        			icon: 'utensils',
        			name: 'restaurante',
        		},
        		{
        			icon: 'spa',
        			name: 'salão',
        		},
        		{
        			icon: 'dumbbell',
        			name: 'academia',
        		},
        		{
        			icon: 'cocktail',
        			name: 'bar',
        		},
        		{
        			icon: 'shopping-bag',
        			name: 'loja',
        		},
        	],            
        };
    },
  }
</script>