export default {  
  setPosts(state, payload) {
    state.posts = payload;
  },
  setPost(state, payload) {
    state.post = payload;
  },
  loading(state, payload) {
    state.loading = payload;
  },
};