export default {  
  loading(state, value){
    state.isLoading = value;
  },
  loadingSkeleton(state, value){
    state.loadingSkeleton = value;
  },
  setSections(state, value){
    state.sections = value;
  },
  setError(state, value){
    state.error = value;
  },
};