import Site from '@/api/Site';

export default {
  loading({commit}, value){    
    commit('loading', value);
  }, 
  setError({commit}, err){
    commit('loading', false);

    let message = '';

    if (err.response) {
      message = '🚨 '+err.response.data.message;
    } else if (err.request) {
      message = '🚨 '+err.message;
    } else if(err != ''){
      message = err;
    }
    
    if(message == ''){
      commit('setError', {open: false, message: message, key: (new Date().getTime())});  
    }else{
      commit('setError', {open: true, message: message, key: (new Date().getTime())});  
    }
  },
  getSections(context, payload) {
    context.commit('loadingSkeleton', true);
    return new Promise(function(resolve, reject){
        Site.getSections(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          context.commit('setSections', data);
          context.commit('loadingSkeleton', false);
          
          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  }, 
};