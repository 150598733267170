import Blog from '@/api/Blog';

export default { 
  getPostsBlogs(context, payload) {
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Blog.getPostsBlog(payload).then(function(response){
          let data = response.data.data;
          
          context.commit('setPosts', data);
          context.commit('loading', false);

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  getPostDetail(context, payload) {
    context.commit('Site/loading', true, {root: true});
    return new Promise(function(resolve, reject){
        Blog.blogDetail(payload).then(function(response){
          let data = response.data.data;
          
          context.commit('setPost', data);
          context.commit('Site/loading', false, {root: true});

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
};