<template>
	<div></div>
</template>
<script>
	import {mapGetters, mapActions} from 'vuex';

	export default{
		computed: {
			...mapGetters('Site', ['error']),
		},
		methods:{
			...mapActions('Site', ['setError']),
		},
		mounted(){
			this.$fire({
			  title: "Erro",
			  text: this.error.message,
			  type: "error",			  
			}).then(r => {
				this.setError('');
			});
		}
	}
</script>