<template>
  <div class="content-header-fixed" v-if="isHome()">
		<div class="content-header-fixed-itens" align="center">
			<div class="site__search layout-02 d-none d-lg-block">
				<a title="Close" href="#" class="search__close">
						<i class="la la-times"></i>
				</a><!-- .search__close -->
				<form-search></form-search>
      </div>
		</div>
	</div>
</template>
<style scoped>
.content-header-fixed {
	position: sticky;
	top: 0;
	left: 0;
	background: rgb(0, 152, 218);
	padding: 10px 15px;
	box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
	z-index: 2;
}
.content-header-fixed-itens-itens {
		display: flex;
    align-items: center;
    justify-content: center;
	}
</style>
<script>
	import FormSearch from '@/views/Home/Banners/FormSearch';   
	export default {
		components: {
			FormSearch
		},
		methods: {
			isHome(){
				let result = true;
				
				if(document.location.pathname == '/'){
					result = false;
				}

				return result;
			}
		}
	}
</script>