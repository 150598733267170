import axios from 'axios';

export default {
	async getSections(){
		return axios.get('/api/v1/site/get-sections');
	},
	async getTeams(){
		return axios.get('/api/v1/site/get-teams');
	},
	async getPlans(){
		return axios.get('/api/v1/site/get-plans');
	},
	async getDepoiments(){
		return axios.get('/api/v1/site/get-depoiments');
	},
	async getCategories(params){
		return axios.get('/api/v1/site/get-categories', {params: params});
	},
	async saveLeadContact(params){
		return axios.post('/api/v1/site/save-lead-contact', params);
	},
	async getStates(params){
		return axios.get('/api/v1/site/get-states', {params: params});
	},
	async getCities(params){
		return axios.get('/api/v1/site/get-cities-core', {params: params});
	},
	async geocoder(address, token, proximity){
		let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${token}&country=BR&proximity=${proximity}`;
		return axios.get(url);
	}
}