<template>
	<footer id="footer" class="footer">
		<div class="container">
			<div class="footer__top">
				<div class="row">
					<div class="col-lg-5">
						<div class="footer__top__info">
							<a title="Logo" href="/" class="footer__top__info__logo">
								<img style="max-width: 300px !important" src="@/assets/images/assets/logo.png" alt="Diskcerto Digital">
							</a>
							<p class="footer__top__info__desc">Encontre lugares maravilhosos próximo a você.</p>
							<div class="footer__top__info__app">
								<a title="App Store" href="https://apps.apple.com/br/app/disk-certo-digital/id1092958327" class="banner-apps__download__iphone"><img src="@/assets/images/assets/app-store.png" alt="App Store"></a>
								<a title="Google Play" href="https://play.google.com/store/apps/details?id=br.com.diskcerto.app&hl=pt_BR" class="banner-apps__download__android"><img src="@/assets/images/assets/google-play.png" alt="Google Play"></a>
							</div>
						</div>
					</div>
					<div class="col-lg-2">
						<aside class="footer__top__nav">
							<h3>Sitemap</h3>
							<ul>
								<li><a title="Quem somos" :href="$root.getUrlPath('about')">Quem somos</a></li>
								<li><a title="Blog" :href="$root.getUrlPath('blog')">Blog</a></li>
								<li>
									<router-link title="Politica Privacidade" :to="{name: 'politics'}">
									Política Privacidade</router-link>
								</li>
							</ul>
						</aside>
					</div>
					<div class="col-lg-2">
						<aside class="footer__top__nav">
							<h3></h3>
							<ul>
								<li><a title="Planos empresariais" :href="$root.getUrlPath('plans')">Planos empresariais</a></li>
								<li><a title="Seja um representante" :href="$root.getUrlPath('reseller')">Seja um representante</a></li>
								<li><a title="Fale Conosco" :href="$root.getUrlPath('contact')">Fale Conosco</a></li>
							</ul>
						</aside>
					</div>
					<div class="col-lg-3">
						<aside class="footer__top__nav footer__top__nav--contact">
							<h3>Contato</h3>
							<p>Email: diskcerto@diskcertodigital.com.br</p>
							<p>Telefone: (65) 3223-9002</p>
							<ul>
								<li class="facebook">
									<a title="Facebook" href="https://www.facebook.com/DiskCertoDigitalCaceres/?ref=bookmarks" rel="oppener" target="_blank">
										<i class="la la-facebook-f"></i>
									</a>
								</li>
								<!-- <li class="twitter">
									<a title="Twitter" href="#">															
										<i class="la la-twitter"></i>
									</a>
								</li>
								<li class="youtube">
									<a title="Youtube" href="#">								
										<i class="la la-youtube"></i>
									</a>
								</li> -->
								<li class="instagram">
									<a title="Instagram" href="https://www.instagram.com/diskcertodigital/"
									target="_blank" rel="oppener">
										<i class="la la-instagram"></i>
									</a>
								</li>
							</ul>
						</aside>
					</div>
				</div>
			</div><!-- .top-footer -->
			<div class="footer__bottom">
				<p class="footer__bottom__copyright">copy © {{ dateYear }} DiskCerto Digital. Todos os direitos reservados. Feito por <a title="4Tech Team" href="https://4tech.mobi">4tech.mobi</a></p>
			</div><!-- .top-footer -->
		</div><!-- .container -->
	</footer><!-- site-footer -->
</template>
<script>
	export default{
		data(){
			return{
				dateYear: (this.$moment().format("YYYY"))
			}
		}
	}
</script>