import Partner from '@/api/Partner';

export default { 
  selectedCity(context, payload) {
    context.commit('setCity', payload);
  },
  login(context, payload) {
    context.commit('Site/loading', true, {root:true});
    return new Promise(function(resolve, reject){
        Partner.login(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          localStorage.setItem('disk_token', data.token);
          context.commit('setPartnerLogin', data);
          context.commit('Site/loading', false, {root:true});

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  getUser(context, payload) {
    context.commit('Site/loading', true, {root:true});
    return new Promise(function(resolve, reject){
        Partner.getUser(payload).then(function(response){
          let data = response.data.data;
                    
          context.commit('setPartnerLogin', data);
          context.commit('Site/loading', false, {root:true});

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  searchList(context, payload) {
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Partner.getList(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          context.commit('setPartners', data);
          context.commit('loading', false);

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  lastPartnerView(context, payload) {
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Partner.lastPartnerView(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          context.commit('setLastPartnerViews', data);
          context.commit('loading', false);

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  partnerDetails(context, payload) {
    context.commit('loading', true);
    return new Promise(function(resolve, reject){
        Partner.getDetail(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          context.commit('setPartner', data.partner);
          context.commit('setPartnerRelated', data.related);
          context.commit('loading', false);

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  partnerRatings(context, payload) {    
    return new Promise(function(resolve, reject){
        Partner.getPartnerRatings(payload).then(function(response){
          let data = response.data.data;

          context.commit('setPartnerRating', data);          

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  getCities(context, payload) {    
    return new Promise(function(resolve, reject){
        Partner.cities(payload).then(function(response){
          let data = response.data.data;
          //clear cart
          context.commit('setCities', data);          

          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
  setClickStats(context, payload) {
    return new Promise(function(resolve, reject){
        Partner.clickStats(payload).then(function(response){
          let data = response.data.data;      
          resolve(data);
        }).catch(error =>{
           context.dispatch('Site/setError', error, { root: true });
           reject()
        });
    });
  },
};