import Vue from 'vue';
import VueJQuery from 'vue-jquery';
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSimpleAlert from "vue-simple-alert";
import Skeleton from 'vue-loading-skeleton';
import VueLazyload from 'vue-lazyload';
import { VueMaskFilter } from 'v-mask';
import Tooltip from 'vue-directive-tooltip';
import VueCurrencyFilter from 'vue-currency-filter';
import vSelect from 'vue-select'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import 'leaflet/dist/leaflet.css';

import '@/assets/js/main.js';

Vue.use(VueJQuery);
// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
Vue.use(VueSimpleAlert);
Vue.use(Skeleton);
Vue.use(VueLazyload);
Vue.use(require('vue-moment'));
Vue.filter('VMask', VueMaskFilter);
Vue.use(Tooltip);
Vue.component('v-select', vSelect);
Vue.use(VueCurrencyFilter,
{
  symbol : 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})