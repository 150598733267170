<template>
  <div id="app">
    <div id="wrapper"> 
      <loading :active.sync="isLoading"
          :is-full-page="true"></loading>
      
      <header-top></header-top>
      
      <router-view />

      <modal-error v-if="error.open"></modal-error>

      <footer-site></footer-site>
    </div>
  </div>
</template>

<script>
  import HeaderTop from '@/components/Header';
  import FooterSite from '@/components/Footer';
  import ModalError from '@/components/Modals/ModalError';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {mapGetters, mapActions} from 'vuex';  
  
  export default {
    name: 'App',
    components: {
      Loading,
      HeaderTop,
      FooterSite,
      ModalError
    },
    metaInfo: {
      htmlAttrs: {
        lang: 'pt-BR',
      },          
    },
    computed: {
      ...mapGetters('Site', ['isLoading', 'error']),
      ...mapGetters('Partner', ['city']),
    },
    methods: {
      ...mapActions('Partner', ['selectedCity']),
    },
    mounted(){
      if(Object.keys(this.city).length == 0){
        this.selectedCity({name:"Cáceres, MT",slug:"caceres"});
      }
    }
  }
</script>
<style>  
  @import './assets/libs/bootstrap/css/bootstrap.min.css';
  @import './assets/fonts/jost/stylesheet.css';
  @import './assets/libs/line-awesome/css/line-awesome.min.css';
  @import './assets/libs/fontawesome-pro/css/fontawesome.css';
  @import './assets/libs/slick/slick-theme.css';
  @import './assets/libs/slick/slick.css';
  @import './assets/css/style.css';
  @import './assets/css/responsive.css';
</style>