import store from '@/store';

export async function protectedPage(to, from, next, permission){
  try {  	  	  	
  	let user = await store.getters['Partner/user'];
    
    if(Object.keys(user).length == 0){
      user = await store.dispatch('Partner/getUser');
    }    

    // return;
    if (typeof user != 'undefined' && Object.keys(user).length > 0) {      
      
      if(typeof permission != 'undefined' && permission != ''){        
        if(user.plan.permissions.indexOf(permission) != -1){
          next();
        }else{          
          store.dispatch('Site/setError', 'Você não possui previlégios para acessar essa página. Atualize o seu plano agora mesmo!')
          next({
            name: "partner-dashboard"
          })      
        }
      }else{
        next();  
      }
      
    }else{    	
      next({
        name: "partner-login"
      })  
    }
  } catch (e) {
    next({
      name: "partner-login"
    })
  }
}