export default {
   methods: {
   		getUrlPath: function(name, params){
			let link = this.$router.resolve({name: name, params: params});
			
			return link.href;
		},  	
	  	async getAllData(form){	     	         
	         let formData = new FormData(form);
	         const data = {}; 
	         // need to convert it before using not with XMLHttpRequest
	         for (let [key, val] of formData.entries()) {
	               Object.assign(data, {[key]: val});
	         }	        	        

	         return data;
        },
   }
}

