import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
	isLoading: false,    
	loadingSkeleton: false,
	sections: [],
	error: {
		key: (new Date().getTime()),
		open: false,
		message: '',
	},
  },
  actions,
  getters,
  mutations,
};