export default {  
  setCities(state, payload) {
    state.cities = payload;
  },
  setCity(state, payload) {
  	localStorage.setItem('disk_city', JSON.stringify(payload));
    state.city = JSON.stringify(payload);
  },
  setPartners(state, payload) {
    state.partners = payload;
  }, 
  setPartnerLogin(state, payload) {
    state.user = payload;
  }, 
  setPartnerRating(state, payload) {
    state.ratings = payload;
  },
  setPartner(state, payload) {
    state.partner = payload;
  },
  setPartnerRelated(state, payload) {
    state.related = payload;
  }, 
  loading(state, payload) {
    state.loading = payload;
  },
  setLastPartnerViews(state, payload) {
    state.lastPartners = payload;
  },
};